import React from "react"
import styled, { css } from "styled-components"
import Img from "gatsby-image"
import CSS from "../config/css"

const PhotoGridContainer = styled.div`
  display: flex;
  margin-bottom: ${CSS.margin};
  justify-content: space-between;
  @media ${CSS.mq.medium} {
    margin-bottom: ${CSS.gutter};
  }
`

const Photo = styled.div`
  flex: 1;
  margin-bottom: 0;
  padding: 0 5px;
  &.rec {
    flex: 0.75;
  }
  :nth-child(3) {
    display: none;
  }
  @media ${CSS.mq.medium} {
    :last-child {
      display: block;
    }
  }
`

const PhotoGrid = props => {
  return (
    <PhotoGridContainer key={props.name}>
      {props.images.map((image, index) => {
        const curImg = image.node.childImageSharp.fluid
        let curClass

        if (curImg.aspectRatio > 1) {
          curClass = "full"
        } else if (curImg.aspectRatio !== 1) {
          curClass = "rec"
        } else {
          curClass = null
        }

        return (
          <Photo key={image.node.id} className={curClass}>
            <Img
              fluid={curImg}
              alt={curImg.originalName
                .match(/([^\/]+)-[A-z0-9]{0,}\.[a-z]{3}\/?$/)[1]
                .replace(/-/g, " ")}
            />
          </Photo>
        )
      })}
    </PhotoGridContainer>
  )
}

export default PhotoGrid

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Link from "../components/link"
import { ButtonLink } from "../components/button"
import { Row, FullRow, Shim, PageTitle, FlexItem } from "../components/row"
import { Box } from "../components/box"
import PhotoGrid from "../components/photo-grid"
import SEO from "../components/seo"

const pageTitle = "Cupcakes"
const ImagesQuery = graphql`
  query {
    designerCupcakes: allFile(
      filter: { relativePath: { regex: "/designer-cupcakes/" } }
    ) {
      edges {
        node {
          ...customFluid
        }
      }
    }

    signatureCupcakes: allFile(
      filter: { relativePath: { regex: "/signature-cupcakes/" } }
    ) {
      edges {
        node {
          ...customFluid
        }
      }
    }

    counter: allFile(filter: { relativePath: { regex: "/front-counter/" } }) {
      edges {
        node {
          ...customFluid
        }
      }
    }
  }
`

export default () => (
  <Layout>
    <SEO title={pageTitle} />
    <Row>
      <PageTitle>{pageTitle}</PageTitle>
      <StaticQuery
        query={ImagesQuery}
        render={data => {
          return (
            <>
              <PhotoGrid
                name="designerCupcakes"
                images={data.designerCupcakes.edges}
              />
              <Shim>
                <h2>Designer Cupcakes</h2>
                <p>
                  Why can't cupcakes dress up for the party too?! Whether you
                  need Star Wars, mermaid or even couture cupcakes, we would
                  love to craft your cupcakes to coordinate with your theme. Our
                  Designer Cupcakes start at $4 each and
                  are priced per dozen. We recommend at least a 30 day booking
                  prior to your event. Call us to find out if we're available 
                  for your date.
                </p>
                <p>
                  <ButtonLink
                    to="/cupcakes/gallery/designer-cupcakes"
                    data-title="View full gallery"
                  >
                    View full gallery
                  </ButtonLink>
                </p>
              </Shim>

              <PhotoGrid
                name="signatureCupcakes"
                images={data.signatureCupcakes.edges}
              />
              <Shim>
                <h2>Signature Cupcakes</h2>
                <p>
                  Cupcakes are the dessert for every occasion! At Frosted
                  Cakery, our cupcakes are People's Choice award-winning
                  deliciousness, unique to any other experience found in the
                  Central Valley. We offer over 12 of our most popular flavors
                  daily in our shop, along with other decadent desserts and
                  cakes. We make our cupcakes from scratch using high-quality
                  ingredients and a lot of love. Our cupcakes are baked to
                  precise moistness and texture, then piled high with our
                  scratch-made buttercreams, chocolates and confections. Some of
                  our flavors include gourmet fillings to achieve the fullest
                  experience of flavor. We offer over 100 customer-approved
                  flavors including: Salted Caramel, Honey Butter, Red Velvet, Bordeaux,
                  Scotchmallow, French Toast and Bacon, Pink Champagne,
                  Chocolate Peanut Butter Cup and many more inspired flavors.
                </p>

                <p>
                  <a href="https://store.frostedcakery.com/s/appointments">
                    Schedule a consultation
                  </a>{" "}
                  for events larger than 100 servings.
                </p>
                <p>
                  <ButtonLink
                    to="/cupcakes/gallery/signature-cupcakes"
                    data-title="View full gallery"
                  >
                    View full gallery
                  </ButtonLink>

                  <ButtonLink
                    darkgold
                    to="https://store.frostedcakery.com"
                    data-title="Shop online"
                  >
                    Shop online
                  </ButtonLink>
                </p>
              </Shim>
              <PhotoGrid name="counter" images={data.counter.edges} />
              <Shim>
                <h2>At the Counter</h2>
                <p>
                  Frosted Cakery's Fresno Tower District Shop is OPEN
                  Tuesdays-Saturdays 10am-5pm. We offer over
                  12 different choices of our Top Selling, or seasonal, standard
                  and jumbo cupcake flavors. Flavors include: Salted Caramel,
                  Red Velvet, Chocolate Cream Cheese, Chocolate Sundae, Pink
                  Champagne and more. We also offer gluten free and vegan
                  options. Our menu is subject to change based on popularity and
                  holidays. At the counter you can find our signature cannoli,
                  pie pockets, strawberry pizzas, French Macaroons, cake pops,
                  cronuts, cake slices and several flavor
                  choices of our 6” 10-serving signature cakes. Please come
                  visit us and enjoy a cupcake or grab a delicious cake for a
                  last minute celebration!
                </p>

                <p>
                  <ButtonLink
                    darkgold
                    to="menu"
                    data-title="View this week's menu"
                  >
                    View this week's menu
                  </ButtonLink>
                </p>

                <Shim flex>
                  <Box className="gold">
                    <h2 className="title">Tower District</h2>
                    <address style={{ margin: "0", lineHeight: "1.5" }}>
                      1292 N. Wishon, Fresno, CA 93728
                      <br />
                      (559) 917-8880
                      <br />
                      <Link to="https://www.google.com/search?hl=en&q=1292%20N.%20Wishon%20Ave.%20Fresno%20CA">
                        Directions
                      </Link>
                    </address>
                  </Box>
                  <Box className="gold">
                    <h2 className="title">Bullard &amp; West</h2>
                    <address style={{ margin: "0", lineHeight: "1.5" }}>
                      2025 W. Bullard, Fresno, CA 93711
                      <br />
                      (559) 455-8979
                      <br />
                        <Link to="https://www.google.com/search?hl=en&q=2025%20W.%20Bullard%20Ave.%20Fresno%20CA">
                        Directions
                      </Link>
                    </address>
                  </Box>

                </Shim>
              </Shim>
            </>
          )
        }}
      />
    </Row>
  </Layout>
)
